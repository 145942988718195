/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 69번째 로그에서는 S3 인텔리전트 티어링, RDS와 DMS 연동 삽질기, 클라우드프론트 지연 이슈 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "시작하며"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/67/"
  }, "스탠다드아웃_067.log: RDS 인증서 업데이트, 파이썬 2.7 지원 종료, 스톡옵션 등 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.donga.com/news/article/all/20190922/97522045/1"
  }, "정년 60세 더 늘려봐야 실제 은퇴나이는 ‘49.1세’")), "\n"), "\n", React.createElement(_components.h2, null, "단신"), "\n", React.createElement(_components.h2, null, "아마존 S3 인텔리전트 티어링 적용"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/s3/storage-classes/"
  }, "Object Storage Classes – Amazon S3")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/AmazonS3/latest/dev/object-lifecycle-mgmt.html"
  }, "객체 수명 주기 관리 - Amazon Simple Storage Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/glacier/"
  }, "서버 백업 솔루션 | 백업 솔루션 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/amazonglacier/latest/dev/configuring-notifications.html"
  }, "Amazon S3 Glacier의 볼트 알림 구성 - Amazon S3 Glacier")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/lambda-edge%EB%A1%9C-%EA%B5%AC%ED%98%84%ED%95%98%EB%8A%94-on-the-fly-%EC%9D%B4%EB%AF%B8%EC%A7%80-%EB%A6%AC%EC%82%AC%EC%9D%B4%EC%A7%95-f4e5052d49f3"
  }, "AWS Lambda@Edge에서 실시간 이미지 리사이즈 & WebP 형식으로 변환 - 당근마켓 팀블로그 - Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/blogs/aws/new-amazon-s3-storage-class-glacier-deep-archive/"
  }, "New Amazon S3 Storage Class – Glacier Deep Archive | AWS News Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/storage/archival/"
  }, "Archival Cloud Storage  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/storage"
  }, "Cloud Storage: Object Storage  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Turing_machine"
  }, "Turing machine - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.akamai.com/kr/ko/"
  }, "보안, 클라우드 전송, 웹 성능 | 아카마이 테크놀로지스 KR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/awskr/awskrug"
  }, "노래는대한해협을타고(2014) - 제2회 AWSKRUG 세미나 발표자료")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.epnc.co.kr/news/articleView.html?idxno=83274"
  }, "생활TECH - 블랙홀 데이터 5페타바이트, 어떻게 처리했을까? - 테크월드")), "\n"), "\n", React.createElement(_components.h2, null, "RDS와 DMS 연동하면서 Auto Vacuum이 멈춰버린 문제"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/dms/"
  }, "AWS Database Migration Service - Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.postgresql.org/docs/10/wal-intro.html"
  }, "PostgreSQL: Documentation: 10: 30.2. Write-Ahead Logging (WAL)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.postgresql.org/docs/10/view-pg-replication-slots.html"
  }, "PostgreSQL: Documentation: 10: 51.80. pg_replication_slots")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.postgresql.org/docs/10/sql-vacuum.html"
  }, "PostgreSQL: Documentation: 10: VACUUM")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/premiumsupport/knowledge-center/dms-postgresql-fail-slots-in-use/"
  }, "PostgreSQL을 원본으로 사용하고 모든 복제 슬롯이 사용 중이기 때문에 실패하는 AWS DMS 작업 문제 해결")), "\n"), "\n", React.createElement(_components.h2, null, "클라우드프론트 특정 통신망 지연 이슈"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/cloudfront/"
  }, "Content Delivery Network (CDN) | Low Latency, High Transfer Speeds, Video Streaming | Amazon CloudFront")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/awskrug/permalink/2278291878939490/?comment_id=2278301895605155"
  }, "Facebook AWSKRUG - 김현민: 유저그룹 슬랙 General 채널에서 이야기 됐던 부분일수도 있는데요, 공식 가이드 문서대로 IPv6를 사용하신다면 Route 53에 A레코드와 AAAA레코드 2개를 등록해주먄 됩니다. ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/IPv6_address#Domain_Name_System"
  }, "IPv6 address#AAAA Record - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mobile.twitter.com/fortyfourbits/status/1220241946343403520"
  }, "44BITS 기술 블로그 👩‍💻👨‍💻 on Twitter: \"최근 SKT LTE망에서 AWS CF 전송 지연이 발생하고 있습니다. ipv6를 사용하지 않는다면...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mobile.twitter.com/zzerjae/status/1220243114826166272"
  }, "zzerjae on Twitter: \"내 일주일을 순삭한이슈 https://t.co/fzfiGvpjH4\" / Twitter")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
